import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Usatsylacauga from '../assets/images/usatsylacauga.jpg';
import Billbartvicki from '../assets/images/billbartvicki.jpg';
import Markbart from '../assets/images/markbart.jpg';
import Usatsylacaugatable from '../assets/images/usatsylacaugatable.jpg';
import Wademarkryanbailey from '../assets/images/wademarkryanbailey.jpg';
import Sylacaugahh from '../assets/images/sylacaugahh.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Sylacauga: 3-10-01" />
      <div>
        <TripHeader>
          <h2>Sylacauga</h2>
          <p>3-10-01</p>
          <img
            src={Usatsylacauga}
            alt="Bill, Alan, Steven, Tiffany, Mark, Ryan, Bailey, Vicki, Allen Drew, Bart, Kevin, Wade, & Patrick in the Sylacauga Huddle House"
          />
          <ImageCaption>
            Bill, Alan, Steven, Tiffany, Mark, Ryan, Bailey, Vicki, Allen Drew,
            Bart, Kevin, Wade, & Patrick in the Sylacauga Huddle House
          </ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Billbartvicki} alt="Bill, Bart & Vicki" />
            <ImageCaption>Bill, Bart & Vicki</ImageCaption>
          </div>
          <div>
            <img src={Markbart} alt="Mark & Bart" />
            <ImageCaption>Mark & Bart</ImageCaption>
          </div>
          <div>
            <img
              src={Usatsylacaugatable}
              alt="Wade, Steven, Alan, Tiffany, Kevin & Allen Drew"
            />
            <ImageCaption>
              Wade, Steven, Alan, Tiffany, Kevin & Allen Drew
            </ImageCaption>
          </div>
        </ImageGrid>
        <p>
          It has been a while I know, but the Huddle House Fan Club was back at
          their favorite resturant last night. Bailey, Kevin, and Patrick
          introduced ten new members to the Huddle House scene in Sylacauga.
          Alan Cotney, Allen Drew, Wade Drew, Bill Dunn (kid at heart), Vicki
          Cunningham, Bart Holms, Ryan Sligh, Mark Pettus, Tiffany Kennedy, and
          Steven Young went with us for the first time! Check them out in the "
          <Link to="/members">Members</Link>" section. We were a large crowd so
          we couldn't be as personal with the waitress as we like to, but she
          was very nice and friendly. We had great food and got to bring all
          those other friends that have never been. It was great. Peace out!
        </p>
        <ImageGrid>
          <div>
            <img src={Wademarkryanbailey} alt="Wade, Mark, Ryan & Bailey" />
            <ImageCaption>Wade, Mark, Ryan & Bailey</ImageCaption>
          </div>
          <div>
            <img src={Sylacaugahh} alt="Outside" />
            <ImageCaption>Outside</ImageCaption>
          </div>
        </ImageGrid>
      </div>
    </>
  );
}
